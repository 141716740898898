import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import MainPageTemplate from '../components/MainPageTemplate'

const MainPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <MainPageTemplate
        title={frontmatter.title}
        missionStatement={frontmatter.missionStatement}
        missionImage={frontmatter.missionImage}
        registerCTA={frontmatter.registerCTA}
        mapTitle={frontmatter.mapTitle}
        boardTitle={frontmatter.boardTitle}
        boardMembers={frontmatter.boardMembers}
        calendar={frontmatter.calendar}
      />
    </Layout>
  )
}

MainPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    })
  }),
}

export default MainPage

export const mainPageQuery = graphql`
  query MainPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        missionStatement
        missionImage {
          sharp: childImageSharp {
            fluid ( maxHeight: 611, maxWidth: 450, cropFocus: CENTER ){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        registerCTA
        mapTitle
        boardTitle
        boardMembers {
          name
          role
          term
        }
        calendar {
          title
          calendarID
        }
      }
    }
  }
`
// const missionImage = data.markdownRemark.frontmatter.missionImage

// const mainSharpQuery = graphql`
//   query MainSharp($missionImage: String!) {
//     missionSharp: imageSharp(original: {src: {regex: $missionImage }} ) {
//       fluid(maxWidth: 1240 ) {
//         tracedSVG
//         fluid
//         srcSet
//       }
//     } 
//   }
// `